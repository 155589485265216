import React, { PureComponent, useEffect, useState } from 'react';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, LineChart, Line } from 'recharts';
import axios from 'axios'
import moment from "moment"
import { Calendar } from "primereact/calendar"
import './style-statistic.scss'
import { AutoComplete } from 'primereact/autocomplete';
import { useHistory } from "react-router-dom"
import { createHistory } from "../../service/historyAPI"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { getAllTopics } from "../../service/topicAPI.js"
import { getAllSources } from "../../service/sourceAPI.js"
import { useDispatch, useSelector } from "react-redux"



export default function ChartDisplay() {
    const { token, userData } = useSelector((state) => state.user)
    const [sources, setSources] = useState([])
    const [detailPost, setDetailPost] = useState(null)
    const [topics, setTopics] = useState([])
    const [filterTopics, setFilterTopics] = useState(null)
    const [filterSources, setFilterSources] = useState(null)
    const [data, setData] = useState();
    const [srcIdFilter, setSrcIdFilter] = useState("");
    const [topicIdFilter, setTopicIdFilter] = useState("");
    const [filterSourceOptions, setFilterSourceOptions] = useState([])
    const [filterTopicOptions, setFilterTopicOptions] = useState([])
    const [date, setDate] = useState([new Date(new Date().setDate(new Date().getDate() - 10)), new Date()])
    const [queryDate, setQueryDate] = useState(
        `&start=${moment().startOf("year").toISOString()}&end=${moment().endOf("day").toISOString()}`,
    )
    const [sourceStat, setSourceStat] = useState();
    const [topicStat, setTopicStat] = useState();
    const history = useHistory()
    const [topic, setTopic] = useState({
        id: "",
        name: "All topics",
    })
    const keyAllTopic = `${process.env.REACT_APP_API_URL}/topic`
    const keyAllSource = `${process.env.REACT_APP_API_URL}/source`
    const [topicQuery, setTopicQuery] = useState({
        id: "",
        name: "All topics",
    })
    const [source, setSource] = useState({
        id: "",
        name: "All sources",
    })
    const [sourceQuery, setSourceQuery] = useState({
        id: "",
        name: "All sources",
    })
    const getAllTopic = useQuery(keyAllTopic, (query) => getAllTopics({ query, token }), {
        onSuccess: (data) => {
            if (data) {
                setTopics(
                    data?.docs.map((p) => ({
                        id: p.id,
                        name: p.name,
                    })),
                )
            }
        },
    })
    const getAllSource = useQuery(keyAllSource, (query) => getAllSources({ query, token }), {
        onSuccess: (data) => {
            if (data) {
                setSources(
                    data?.docs.map((p) => ({
                        id: p.id,
                        name: p.name,
                    })),
                )
            }
        },
    })

    useEffect(() => {
        fetchData();
    }, [srcIdFilter, topicIdFilter, date])
    useEffect(() => {
        fetchDateData();
    }, [date])
    const handleFilterSourceChange = (event) => {
        setSrcIdFilter(event.target.value);
    };
    const handleFilterTopicChange = (event) => {
        setTopicIdFilter(event.target.value);
    };
    const filterRange = (e) => {
        setDate(e.value)
        if (e?.value[1]) {
            setQueryDate(
                `start=${moment(e.value[0]).startOf("day").toISOString().split('T')[0]}&end=${moment(e.value[1])
                    .endOf("day")
                    .toISOString().split('T')[0]}`,
            )
        } else {
            setQueryDate(
                `start=${moment(e.value[0]).startOf("day").toISOString().split('T')[0]}&end=${moment(e.value[0])
                    .endOf("day")
                    .toISOString().split('T')[0]}`,
            )
        }
    }

    const fetchData = async () => {// Define the API endpoint URL
        let srcQuery = '';
        let topicQuery = '';
        let query = '';
        if (srcQuery.length && topicQuery.length) {
            query = '&sid=' + srcIdFilter + '&tid=' + topicIdFilter
        }
        else if (srcIdFilter.length) {
            query = "&sid=" + srcIdFilter;
        }
        else if (topicIdFilter.length) {
            query = "&tid=" + topicIdFilter;
        }
        const apiUrl = "https://tokien.online/api/content/statistics/monthly/all?" + query +'&'+ queryDate; // Replace with your API URL
        //console.log('api:', apiUrl);
        // Make the Axios GET request
        axios
            .get(apiUrl)
            .then((response) => {
                // Handle the successful response here
                setData(response);
            })
            .catch((error) => {
                // Handle any errors that occurred during the request
                console.error("Error fetching data:", error);
            });

    }

    const fetchDateData = async () => {
        const apiUrl1 = "https://tokien.online/api/content/statistics/source?" + queryDate;
        axios.get(apiUrl1).then((res) => {
            setSourceStat(res?.data?.doc);
        }).catch((error) => {
            // Handle any errors that occurred during the request
            console.error("Error fetching data:", error);
        });

        const apiUrl2 = "https://tokien.online/api/content/statistics/topic?" + queryDate;
        axios.get(apiUrl2).then((res) => {
            setTopicStat(res?.data?.doc);
        }).catch((error) => {
            // Handle any errors that occurred during the request
            console.error("Error fetching data:", error);
        });

    }

    useEffect(() => {
        axios.get('https://tokien.online/api/source')
            .then((response) => {
                // Handle the successful response here
                setFilterSourceOptions(response?.data?.docs);
            })
            .catch((error) => {
                // Handle any errors that occurred during the request
                console.error("Error fetching data:", error);
            });

        axios.get('https://tokien.online/api/topic')
            .then((response) => {
                // Handle the successful response here
                setFilterTopicOptions(response?.data?.docs);
            })
            .catch((error) => {
                // Handle any errors that occurred during the request
                console.error("Error fetching data:", error);
            });
    }, [])
    const searchTopic = (event) => {
        setTimeout(() => {
            let _filterTopic
            if (!event.query.trim().length) {
                _filterTopic = [...topics]
            } else {
                _filterTopic = topics.filter((topic) => {
                    return topic.name.toLowerCase().startsWith(event.query.toLowerCase())
                })
            }
            _filterTopic = [..._filterTopic, { id: "", name: "All topics" }]
            setFilterTopics(_filterTopic)
        }, 250)
    }
    const searchSource = (event) => {
        setTimeout(() => {
            let _filterSource
            if (!event.query.trim().length) {
                _filterSource = [...sources]
            } else {
                _filterSource = sources.filter((source) => {
                    return source.name.toLowerCase().startsWith(event.query.toLowerCase())
                })
            }
            _filterSource = [..._filterSource, { id: "", name: "All sources" }]
            setFilterSources(_filterSource)
        }, 250)
    }

    console.log(data)

    console.log(sourceStat)
    return (
        <div className='main-statistic'>
            <div className='title-statistic'>Contents statistic</div>
            <div className='main-chart'>
                <div className='main-select'>
                <AutoComplete
                        value={filterSourceOptions.find(item => item.id === srcIdFilter)?.name || 'All sources'}
                        suggestions={filterSources}
                        completeMethod={searchSource}
                        field="name"
                        dropdown
                        forceSelection
                        onSelect={(e) => {
                            setSrcIdFilter(e.value.id)
                        }}
                        onChange={(e) => {
                            if (e.value) {
                                setSrcIdFilter(e.value.id)
                            }
                        }}
                        aria-label="Sources"
                    />
                    <AutoComplete
                        value={filterTopicOptions.find(item => item.id === topicIdFilter)?.name || 'All topics'}
                        suggestions={filterTopics}
                        completeMethod={searchTopic}
                        field="name"
                        dropdown
                        forceSelection
                        onSelect={(e) => {
                            setTopicIdFilter(e.value.id)
                        }}
                        onChange={(e) => {
                            if (e.value) {
                                setTopicIdFilter(e.value.id)
                            }
                        }}
                    />
                </div>
                {(data && data.data && data.data.doc) ?
                    <LineChart
                    width={700}
                    height={400}
                    data={data?.data?.doc}
                    margin={{
                        top: 25,
                        right: 30,
                        left: 5,
                        bottom: 5,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Line type="monotone" dataKey="total" stroke="#8884d8" activeDot={{ r: 8 }} />
                </LineChart>
                    :
                    <div className='not-data'>Data not found.</div>
                }
            </div>
            <div className='main-table'>
                <div className="date-picker-table">
                    <Calendar
                        id="range"
                        dateFormat="dd/mm/yy"
                        value={date}
                        onChange={(e) => filterRange(e)}
                        selectionMode="range"
                        placeholder="DD/MM/YYYY - DD/MM/YYYY"
                        readOnlyInput
                        showIcon

                    />
                </div>
                <div className='tables'>
                    <div className='table-content'>
                        <div className='title-table'>Statistic by source</div>
                        <div className='table-container'>{sourceStat?.map((item) => (
                            <div className='table-item' key={item?.sourceid}>
                                <div>{item?.sourcename}</div>
                                <div>{item?.totalcontentspertopic}</div>
                            </div>
                        ))}</div>

                    </div>
                    <div className='table-content'>
                        <div className='title-table'>Statstic by topic</div>
                        <div className='table-container'>{topicStat?.map((item) => (
                            <div className='table-item' key={item?.topicid}>
                                <div>{item?.topicname}</div>
                                <div>{item?.totalcontentspertopic}</div>
                            </div>
                        ))}
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
}