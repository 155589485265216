import React from "react";
import { Document, Page, StyleSheet, Text, View, Font } from "@react-pdf/renderer";
import moment from "moment";
Font.register({
  family: "Ubuntu",
  fonts: [
    {
      src: "https://fonts.gstatic.com/s/questrial/v13/QdVUSTchPBm7nuUeVf7EuStkm20oJA.ttf",
    },
    {
      src: "https://fonts.gstatic.com/s/questrial/v13/QdVUSTchPBm7nuUeVf7EuStkm20oJA.ttf",
      fontWeight: "bold",
    },
    {
      src: "https://fonts.gstatic.com/s/questrial/v13/QdVUSTchPBm7nuUeVf7EuStkm20oJA.ttf",
      fontWeight: "normal",
      fontStyle: "italic",
    },
  ],
});

const styles = StyleSheet.create({
  page: {
    marginTop: 10,
    marginBottom: 20,
    fontFamily: "Ubuntu",
  },
  table: {
    width: "210mm",
    fontSize: 12,
    
  },
  section: { marginLeft: 20, marginRight: 20 },
  row: {
    display: "flex",
    flexDirection: "row",
    borderTop: "1px solid #EEE",
    textAlign: "center",
    marginLeft: 20,
    marginRight: 20,
  },
  header: {
    borderTop: "1px solid #EEE",
    textAlign: "center",
  },
  headerTitle: {
    textAlign: "center",
    fontSize: 22,
    marginBottom: 3,
  },
  description1: {
    textAlign: "center",
    fontSize: 12,
    marginLeft: 20,
    marginRight: 20,
    marginBottom: 3,
  },
  description2: {
    textAlign: "justify",
    fontSize: 12,
    marginLeft: 20,
    marginBottom: 5,
    marginRight: 20,
  },
  bold: {
    fontWeight: "bold",
  },
  // So Declarative and unDRY 👌
  row1: {
    width: "5%",
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 4,
    paddingRight: 4,
    borderLeft: "1px solid #EEE",
  },
  row2: {
    width: "10%",
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 4,
    paddingRight: 4,
    borderLeft: "1px solid #EEE",
  },
  row3: {
    width: "10%",
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 4,
    paddingRight: 4,
    borderLeft: "1px solid #EEE",
    wordBreak: "break-all",
    textAlign: "left",
  },
  row4: {
    width: "15%",
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 4,
    paddingRight: 4,
    borderLeft: "1px solid #EEE",
    wordBreak: "break-all",
    textAlign: "left",
  },
  row5: {
    width: "15%",
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 4,
    paddingRight: 4,
    borderLeft: "1px solid #EEE",
    wordBreak: "break-all",
    textAlign: "left",
  },
  row6: {
    width: "10%",
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 4,
    paddingRight: 4,
    borderLeft: "1px solid #EEE",
    wordBreak: "break-all",
    textAlign: "left",
  },
  row7: {
    width: "10%",
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 4,
    paddingRight: 4,
    borderLeft: "1px solid #EEE",
    wordBreak: "break-all",
    textAlign: "left",
  },
  row8: {
    width: "13%",
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 4,
    paddingRight: 4,
    borderLeft: "1px solid #EEE",
    wordBreak: "break-all",
    textAlign: "left",
  },
  row9: {
    width: "12%",
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 4,
    paddingRight: 4,
    borderLeft: "1px solid #EEE",
    borderRight: "1px solid #EEE",
    wordBreak: "break-all",
    textAlign: "left",
  },
});

function PDFfile({ data, tags, mostReaction, queryDate }) {
  return (
    <>
      <Document>
        <Page style={styles?.page}>
          <View>
            <Text style={styles.headerTitle}>GENERAL NEWSLETTER</Text>
            <Text style={styles.description1}>{`(From 0:00 ${moment(queryDate[0] || new Date()).startOf("day").format("DD/M/YYYY")} đến ${moment(queryDate[1] || new Date()).format("DD/MM/YYYY") == moment(new Date()).format("DD/MM/YYYY") ? `${moment().format("HH")}h${moment().format("mm")}` : "23h59"} ngày ${moment(queryDate[1] || new Date()).format("DD/M/YYYY")})`}</Text>
            <Text style={styles.description2}>{`Qua nắm bắt tính hình trên không gian mạng trong khoảng thời gian from 0h00 ngày ${moment(queryDate[0] || new Date()).startOf("day").format("DD/M/YYYY")} đến ${moment(queryDate[1] || new Date()).format("DD/MM/YYYY") == moment(new Date()).format("DD/MM/YYYY") ? `${moment().format("HH")}h${moment().format("mm")}` : "23h59"} ${moment(queryDate[1] || new Date()).format("DD/M/YYYY")} There are some notable news as follows:`}</Text>
          </View>
          <View>
            {tags && tags?.length
              ? tags.map((p, i) => (
                  <Text style={{ fontSize: 12, marginLeft: 20, marginBottom: 3, fontWeight: "bold" }}>
                    {i + 1}. {p}
                  </Text>
                ))
              : ""}
          </View>
          <View>
            <Text style={{ fontWeight: "bold", marginLeft: 20,marginBottom: 4, marginTop: 4, fontSize: 13 }}>Bản tin nổi bật trong tuần</Text>
          </View>
          <View style={styles?.table}>
            <View
              style={{
                ...styles?.row,
                ...styles.bold,
                ...styles.header,
                ...styles.section,
              }}
            >
              <Text style={styles?.row2}>Title</Text>
              <Text style={{ ...styles?.row3, textAlign: "center" }}>Channel</Text>
              <Text style={{ ...styles?.row4, textAlign: "center" }}>Posted Date</Text>
              <Text style={{ ...styles?.row5, textAlign: "center" }}>Path</Text>
              <Text style={{ ...styles?.row6, textAlign: "center" }}>Number of interactions</Text>
              <Text style={{ ...styles?.row7, textAlign: "center" }}>Violation period
</Text>
              <Text style={{ ...styles?.row8, textAlign: "center" }}>Violations</Text>
              <Text style={{ ...styles?.row9, textAlign: "center", width: "17%" }}>Violation terms</Text>
            </View>

            <View style={styles.row} wrap={true}>
              <Text style={styles.row2}>{mostReaction?.title}</Text>
              <Text style={styles.row3}>{mostReaction.sourcename}</Text>
              <Text style={styles.row4}>{mostReaction.postedat}</Text>
              <Text style={styles.row5}>{mostReaction.sourcelink}</Text>
              <Text style={styles.row6}>{mostReaction.totalReactions?.toString()}</Text>
              <Text style={styles.row7}>{mostReaction?.timeViolation}</Text>
              <Text style={styles.row8}>{mostReaction?.violationContent}</Text>
              <Text style={{...styles.row9, width: "17%"}}>{mostReaction?.violationEnactment}</Text>
            </View>
          </View>
          <View>
            <Text style={{ fontWeight: "bold", marginLeft: 20,marginBottom: 10, marginTop: 10, fontSize: 13 }}>{`Danh sách các posts vi phạm from 0h00 ${moment(queryDate[0] || new Date()).startOf("day").format("DD/M/YYYY")} đến ${moment(queryDate[1] || new Date()).format("DD/MM/YYYY") == moment(new Date()).format("DD/MM/YYYY") ? `${moment().format("HH")}h${moment().format("mm")}` : "23h59"} ngày ${moment(queryDate[1] || new Date()).format("DD/M/YYYY")})`}</Text>
          </View>
          <View style={styles?.table}>
            <View
              style={{
                ...styles?.row,
                ...styles.bold,
                ...styles.header,
                ...styles.section,
              }}
            >
              <Text style={styles?.row1}>NO</Text>
              <Text style={styles?.row2}>Title</Text>
              <Text style={{ ...styles?.row3, textAlign: "center" }}>Channel</Text>
              <Text style={{ ...styles?.row4, textAlign: "center" }}>Posted Date</Text>
              <Text style={{ ...styles?.row5, textAlign: "center" }}>Path</Text>
              <Text style={{ ...styles?.row6, textAlign: "center" }}>Number of interactions</Text>
              <Text style={{ ...styles?.row7, textAlign: "center" }}>Violation period
</Text>
              <Text style={{ ...styles?.row8, textAlign: "center" }}>Violations</Text>
              <Text style={{ ...styles?.row9, textAlign: "center" }}>Violation terms</Text>
            </View>
            {data &&
              data?.length &&
              data.map((row, i) => (
                <View key={i} style={styles.row} wrap={true}>
                  <Text style={styles.row1}>
                    <Text style={styles.bold}>{row?.stt}</Text>
                  </Text>
                  <Text style={styles.row2}>{row?.title}</Text>
                  <Text style={styles.row3}>{row.sourcename}</Text>
                  <Text style={styles.row4}>{row.postedat}</Text>
                  <Text style={styles.row5}>{row.sourcelink}</Text>
                  <Text style={styles.row6}>{row.totalReactions?.toString()}</Text>
                  <Text style={styles.row7}>{row?.timeViolation}</Text>
                  <Text style={styles.row8}>{row?.violationContent}</Text>
                  <Text style={styles.row9}>{row?.violationEnactment}</Text>
                </View>
              ))}
          </View>
        </Page>
      </Document>
    </>
  );
}

export default PDFfile;
