import moment from "moment"

import React, {useEffect, useState} from "react"
import {useMutation, useQuery} from "react-query"
import {getAllPosts, getSimilarPosts} from "../../service/postAPI"
import {getAllSources} from "../../service/sourceAPI"
import {getAllTopics} from "../../service/topicAPI"
//import {getAllTotalContentOfMonth} from '../../service/contentWordpressAPI'
import socket from "../../service/socket.js"
import {createHistory} from "../../service/historyAPI"
import {useSelector} from "react-redux"
// import Statistical from "./Statistical"
import DashboardTable from "./DashboardTable/DashboardTable"
import {makeQueryString} from "../../core/helper"
import axios from "axios"
import ChartDisplay from "../Statistics"
export const FollowTagId = "878aa7a3-8691-49b9-8018-2159a8b55175"
export const size = 10
export const ActionsType = {
  cancelHandle: "cancelHandle",
  handlePost: "handlePost",
}
export const PostType = {
  postedAt: "postedAt",
  totalReactions: "totalReactions",
  follow: "follow",
}
export const SourceType = {
  all: "all",
  facebook: "facebook",
  website: "website",
  youtube: "youtube",
}

const keyTotalTopic = `${process.env.REACT_APP_API_URL}/topic?page=1&pageSize=1`
const keyTotalPostFollow = `${process.env.REACT_APP_API_URL}/content?page=1&pageSize=1`
const keyTotalTodayTopic = `${process.env.REACT_APP_API_URL}/topic?page=1&pageSize=0&updatedAt=${moment()
  .startOf("day")
  .toISOString()}`
const keyTotalContentToday = `${process.env.REACT_APP_API_URL}/content?page=1&pageSize=1&fromDate=${moment()
  .startOf("day")
  .toISOString()}&toDate=${moment().endOf("day").toISOString()}`
const keyTotalFBFollow = `${process.env.REACT_APP_API_URL}/source?page=1&pageSize=0&type=FB_PAGE`
const keyTotalToday = `${process.env.REACT_APP_API_URL}/content/totalSource/dashboard`
const keyTotalWebFollow = `${process.env.REACT_APP_API_URL}/source?page=1&pageSize=0&type=WEBSITE`
const keySimilarPost = `${process.env.REACT_APP_API_URL}/content/outstanding`
//const keyTotalContentPerDay = `${process.env.REACT_APP_API_URL}/content/statistics/monthly`
const defaultParams = {
  page: 0,
  total: 0,
  first: 0,
  desc: true,
  pageSize: size,
  userHandle: "notHandle",
}

const makeQuery = (params) => {
  const query = {
    ...params,
  }
  delete query.total
  delete query.first
  return makeQueryString({
    ...query,
    page: params.page + 1,
  })
}
export const cluster = [	
  //{ name: "All", value: [] },	
  { name: "cluster 1", value: ['862a5057-ad35-4afc-962d-4d7168392cf6', 'ab8a3e86-579d-447c-9f86-5ab934dc3ec1', '1ba755a6-887b-43ec-8962-268af9857d83'] },	
  { name: "cluster 2", value: ['7937249c-29af-4874-a200-6ba5d7bf9861', '03ba12d0-2f7f-457f-b1e2-63c4c9af069b', '0d63e4a8-92e1-45be-a3cf-51af7668ac2e'] },	
  { name: "cluster 3", value: ['6c339d64-d494-4a6c-aefb-11e92be0f6e9', '499f87d0-2401-450e-8bf0-10575798dac4', '61774dae-5d42-47bf-bb5c-2769e82c1ea5'] }	
]
const QuickProcess = (props) => {
  const token = useSelector((state) => state.user.token)
  const [lineOptions, setLineOptions] = useState(null)
  const [type, setType] = useState(PostType.postedAt)
  const [source, setSource] = useState(SourceType.all)
  const [params, setParams] = useState({
    ...defaultParams,
    sortBy: PostType.postedAt,
    // type: "FB_POST,WEBSITE_POST,YOUTUBE",
    toDate: new Date().toISOString(),
  })
  const totalTopic = useQuery(keyTotalTopic, (query) => getAllTopics({query, token}))
  //const totalContentPerDay = useQuery(keyTotalContentPerDay,(query) => getAllTotalContentOfMonth({query,token}))
  const totalPostFollow = useQuery(keyTotalPostFollow, (query) => getAllTopics({query, token}))
  const totalTodayTopic = useQuery(keyTotalTodayTopic, (query) => getAllTopics({query, token}))
  const totalContentToday = useQuery(keyTotalContentToday, (query) => getAllPosts({query, token}))
  const totalFBFollow = useQuery(keyTotalFBFollow, (query) => getAllSources({query, token}))
  const totalPostToday = useQuery(keyTotalToday, (query) => getAllPosts({query, token}))
  const totalWebFollow = useQuery(keyTotalWebFollow, (query) => getAllSources({query, token}))
  // const similarPost = useQuery(keySimilarPost, (query) => getSimilarPosts(query, token))
  const keyPosts = `${process.env.REACT_APP_API_URL}/content?${makeQuery(params)}`

  let posts = useQuery(keyPosts, (query) => getAllPosts({query, token}))
  const [selectedCluster, setSelectedCluster] = useState(" ");	
  const [clusterContent, setClusterContent] = useState();	
  const [tmpContent, setTmpContent] = useState();	
  const [posts2, setPosts2] = useState();
  const applyLightTheme = () => {
    const lineOptions = {
      plugins: {
        legend: {
          labels: {
            color: "#495057",
          },
        },
      },
      scales: {
        x: {
          ticks: {
            color: "#495057",
          },
          grid: {
            color: "#ebedef",
          },
        },
        y: {
          ticks: {
            color: "#495057",
          },
          grid: {
            color: "#ebedef",
          },
        },
      },
    }
    setLineOptions(lineOptions)
  }

  const applyDarkTheme = () => {
    const lineOptions = {
      plugins: {
        legend: {
          labels: {
            color: "#ebedef",
          },
        },
      },
      scales: {
        x: {
          ticks: {
            color: "#ebedef",
          },
          grid: {
            color: "rgba(160, 167, 181, .3)",
          },
        },
        y: {
          ticks: {
            color: "#ebedef",
          },
          grid: {
            color: "rgba(160, 167, 181, .3)",
          },
        },
      },
    }

    setLineOptions(lineOptions)
  }
  const handleChangeType = (type) => {
    setType(type)
    switch (type) {
      case PostType.postedAt:
        setParams({
          ...params,
          sortBy: PostType.postedAt,
        })
        break
      case PostType.follow:
        // setParams({
        //   ...params,
        //   tagIds: FollowTagId,
        // })
        break
      case PostType.totalReactions:
        setParams({
          ...params,
          sortBy: PostType.totalReactions,
          fromDate: moment().startOf("day").toISOString(),
          toDate: moment().endOf("day").toISOString(),
        })
        break
    }
  }
  const handleChangeSource = (source) => {
    setSource(source)
    const cloneParams = {...params}
    switch (source) {
      case "all":
        delete cloneParams.type
        setParams(cloneParams)
        break
      case "facebook":
        setParams({
          ...params,
          type: "FB_POST",
        })
        break
      case "website":
        setParams({
          ...params,
          type: "WEBSITE_POST",
        })
        break
      case "youtube":
        setParams({
          ...params,
          type: "YOUTUBE",
        })
        break
      case null:
        delete cloneParams.type
        setParams(cloneParams)
        break
    }
  }
  const handlePageChange = (e) => {
    console.log("e", e)
    if (typeof e === "string") {
      if (e.length > 0) {
        setParams({
          ...params,
          search: e,
        })
      } else {
        let cloneParams = {...params}
        delete cloneParams.search
        setParams(cloneParams)
      }
    } else if (e?.filters) {
      let filter
      if (e.filters?.tagIds) {
        const filterValue = e.filters.tagIds?.value
        if (filterValue) {
          filter = {tagIds: filterValue}
          setParams({
            ...params,
            ...(e?.page && {page: e.page}),
            ...(e?.first && {first: e.first}),
            ...filter,
          })
        } else {
          const cloneParams = {...params}
          delete cloneParams.tagIds
          setParams({
            ...cloneParams,
            ...(e?.page && {page: e.page}),
            ...(e?.first && {first: e.first}),
          })
        }
      }
    } else {
      setParams({
        ...params,
        page: e.page,
        first: e.first,
      })
    }
  }
  useEffect(() => {	
    socket.on("update_dashboard", (data) => {	
      // totalTopic.refetch();	
      totalPostFollow.refetch()	
      totalContentToday.refetch()	
      //totalContentPerDay.refetch()	
      posts.refetch()	
      if (data?.type === "FB_POST") {	
        totalFBFollow.refetch()	
        totalPostToday.refetch()	
      } else {	
        totalWebFollow.refetch()	
        totalPostToday.refetch()	
      }	
    })	
  }, [posts, totalContentToday, totalFBFollow, totalPostToday, totalPostFollow, totalWebFollow, clusterContent])

  useEffect(() => {
    if (props.colorMode === "light") {
      applyLightTheme()
    } else {
      applyDarkTheme()
    }
  }, [props.colorMode])
  const addHistory = useMutation(createHistory, {
    onError: (e) => {
      console.log(e)
    },
  })
  useEffect(() => {
    addHistory.mutate({newData: {screen: "Dashboard", description: "View dashboard"}, token})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  //console.log(totalContentPerDay)
  useEffect(() => {	
    // Fetch content when the selected cluster changes	
    if (selectedCluster == "All") {	
      setClusterContent(null)	
      return;	
    }	
    else {	
      const fetchClusterContent = async () => {	
        const result = cluster.find(item => item.name === selectedCluster);	
        const contentIds = result.value;	
        console.log(contentIds);	
        const contentResults = [];	
        try {	
          for (const contentId of contentIds) {	
            await axios.get(`https://tokien.online/api/content/${contentId}`)	
              .then(res => {	
                contentResults.push(res?.data?.doc)	
                // console.log(res);	
              })	
            // setTmpContent(response)	
            // contentResults.push(tmpContent);	
          }	
          console.log(contentResults);	
          setClusterContent({	
            dataUpdatedAt: 1695889323163, error: null, errorUpdateCount: 0, errorUpdatedAt: 0,	
            failureCount: 0, isError: false, isFetched: true, isFetchedAfterMount: true, isFetching: false,	
            isIdle: false, isLoading: false, isLoadingError: false, isPlaceholderData: false,	
            isPreviousData: false,	
            isRefetchError: false,	
            isRefetching: false,	
            isStale: true,	
            isSuccess: true,	
            status: "success", data: { code: 200, page: 1, pageSize: 1, total: contentIds.length, docs: contentResults }	
          });	
        } catch (error) {	
          console.error(`Error fetching content: ${error}`);	
        }	
      };	
      fetchClusterContent();	
    }	
  }, [selectedCluster]);	
  console.log(clusterContent)	
  console.log(posts)	
  const handleFilterChange = (event) => {	
    setSelectedCluster(event.target.value);	
  };
  const handleClusterClick = (value) => {
    setSelectedCluster(value);
  }
  return (
    <div className="grid">
      <DashboardTable
        dataList={clusterContent ? clusterContent : posts}
        handleChangeType={handleChangeType}
        handleChangeSource={handleChangeSource}
        type={type}
        source={source}
        handlePageChange={handlePageChange}
        handleFilterChange={handleFilterChange}
        handleClusterClick={handleClusterClick}
        selectedCluster={selectedCluster}
        params={params}
      />
    </div>
  )
}

export default React.memo(QuickProcess)
