import axios from "axios";
export const handleError = (err) => {
  if (err.response.data.msg) {
    throw new Error(err.response.data.msg);
  } else {
    throw new Error(err.message);
  }
};
export const getAllAuthors = async ({ query, token }) => {
  // &createdAt_gte=${}&createdAt__lte=${}
  try {
    const res = await axios.get(`${query.queryKey[0]}`, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    if (res.data && res.data?.code && res?.data?.code != 200) {
      throw new Error(res?.data?.message || "Catch error");
    }
    return res.data;
  } catch (error) {}
};
export const getAuthorById = async ({ query, token }) => {
  // &createdAt_gte=${}&createdAt__lte=${}
  try {
    const res = await axios.get(`${query.queryKey[0]}`, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    if (res.data && res.data?.code && res?.data?.code != 200) {
      throw new Error(res?.data?.message || "Catch error");
    }
    return res.data;
  } catch (error) {}
};
export const createAuthor = async (newData) => {
  const res = await axios.post(`${process.env.REACT_APP_API_URL}/author`, newData);
  return res.data;
};
export const updateAuthor = async ({ id, newData, token }) => {
  newData = { ...newData };
  const res = await axios.put(`${process.env.REACT_APP_API_URL}/author/${id}`, newData, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  if (res.data && res.data?.code && res?.data?.code != 200) {
    throw new Error(res?.data?.message || "Catch error");
  }
  return res.data;
};

export const deleteAuthor = async ({ id }) => {
  const res = await axios.delete(`${process.env.REACT_APP_API_URL}/author/${id}`);
  return res.data;
};
